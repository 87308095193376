import { FC, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { WelcomeModalWrapper } from '@/entities/trader'
import { DepositPollingWrapper } from '@/features/deposit'
import { ModalProvider } from '@/shared/lib'
import { AppLoader } from '@/shared/ui'
import { Menu } from '@/widgets/Menu'

/**
 * Global layout of application. Use as element in {appRouteConfig}
 * @constructor
 */
export const Layout: FC = () => {

  return (
    <ModalProvider>
      <WelcomeModalWrapper>
        <DepositPollingWrapper>
          <Menu />
          <Suspense fallback={<AppLoader />}>
            <Outlet />
          </Suspense>
        </DepositPollingWrapper>
      </WelcomeModalWrapper>
    </ModalProvider>
  )
}
