import { fromNano, toNano } from '@ton/core'

export const converterNanoToMicro = ( ton: number | string | bigint | undefined) => {
  if (!ton) return '0'

  const microTonConversion = BigInt(1_000)

  return fromNano(toNano(ton) / microTonConversion)
}

export const converterMicroToTON = (microTon: number | string | bigint | undefined) => {
  if (!microTon) return 0n

  const tonConversion = BigInt(1_000_000)

  return toNano(microTon) / tonConversion
}
