import { fromNano, toNano } from '@ton/core'

import { converterNanoToMicro } from './convert'
import { microTonValue } from './nanoton'

export const formatTon = (
  ton: bigint | number | string | undefined,
  microThreshold?: bigint | string,
  rounding?: number,
): string => {
  if (!ton) return '0'

  if (!microThreshold) return rounding ? Number(fromNano(ton)).toFixed(rounding ?? 0) : fromNano(ton)

  const isMicro = toNano(microThreshold) < toNano(microTonValue)

  const value = isMicro
    ? converterNanoToMicro(ton)
    : fromNano(ton)

  return rounding
    ? `${Number(value).toFixed(rounding)}${isMicro ? 'μ' : ''}`
    : `${value}${isMicro ? 'μ' : ''}`
}
