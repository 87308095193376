import { Popover as HeadlesUIPopover, PopoverButton, PopoverPanel, PopoverProps as HeadlesUIPopoverProps } from '@headlessui/react'
import { FC, ReactNode } from 'react'

import s from './Popover.module.scss'

export interface PopoverProps extends HeadlesUIPopoverProps {
  buttonContent: ReactNode;
}

export const Popover: FC<PopoverProps> = ({ buttonContent, children }) => {

  return (
    <HeadlesUIPopover className={s.container}>
      <PopoverButton className={s.button}>{buttonContent}</PopoverButton>
      <PopoverPanel className={s.content} anchor="bottom">
        {children}
      </PopoverPanel>
    </HeadlesUIPopover>
  )
}
