import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { useModal } from '@/shared/lib'

import { WelcomeModal } from '../ui'

const welcomeKey = 'firstEntry'

export const useWelcomeModal = () => {
  const [isFirstEntry, setIsFirstEntry] = useLocalStorage(welcomeKey, true)
  const { openModal } = useModal()

  useEffect(() => {
    if (!isFirstEntry) return

    openModal({
      Component: WelcomeModal,
    })
    setIsFirstEntry(false)
  }, [])
}
