import Clarity from '@microsoft/clarity'

class DepositEvents {
  openDeposit = () => Clarity.event('open_deposit')

  depositStart = () => Clarity.event('start_deposit')

  depositSuccess = () => Clarity.event('success_deposit')

  openDepositAfterCallToAction = () => Clarity.event('open_deposit_after_call_to_action')
}

export const depositEvents = new DepositEvents()
