/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface GetOrdersResponse {
  orders: Order[];
  pagination: Pagination;
}

export interface GetRoundOrdersResponse {
  orders: Order[];
  round: Round;
  pagination: Pagination;
}

export interface Order {
  /**
   * ID ордера
   * @format int64
   */
  id: number;
  /** @format int64 */
  trader_id: number;
  trader_name: string;
  /**
   * Время (мс) открытия ордера
   * @format int64
   */
  entry_time: number;
  /** Цена открытия ордера */
  entry_price: Ton;
  /**
   * Время (мс) закрытия ордера
   * @format int64
   */
  exit_time?: number;
  /** Цена после закрытия ордера */
  exit_price?: Ton;
  /** Объем ордера */
  value: Ton;
  /** Длинная позиция или короткая */
  is_long: boolean;
  /** Плечо ордера (десятичное число на отрезке [1; 4]) */
  leverage: string;
  /** Ликвидационная цена ордера */
  liquidation_price?: Ton;
  /** Активен ли ордер */
  is_active: boolean;
  /** Текущее сальдо ордера */
  saldo?: Ton;
}

export interface NewOrderRequest {
  /** Объем ордера */
  value: Ton;
  /** Длинная позиция или короткая */
  is_long: boolean;
  /** Плечо ордера */
  leverage: string;
}

export interface NewOrderResponse {
  /** Trader */
  trader: Trader;
  order: Order;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface FixOrderRequest {
  /**
   * id ордера
   * @format int64
   */
  order_id: number;
}

export interface FixOrderResponse {
  /** Trader */
  trader: Trader;
  order: Order;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface Round {
  stage: "pre_round" | "active_stage" | "post_round" | "in_archive";
  /**
   * ID раунда
   * @format int64
   */
  id: number;
  /**
   * Round start time (ms)
   * @format int64
   */
  start_time: number;
  /**
   * Время (ms) начала фазы трейдинга
   * @format int64
   */
  trading_start_at: number;
  /**
   * Время (ms) окончания фазы трейдинга
   * @format int64
   */
  trading_finish_at: number;
  /**
   * Round finish time (ms)
   * @format int64
   */
  finish_time: number;
  /** Информация про мемкоин */
  coin_info: Coin;
  /**
   * Время (ms), прошедшее с начала раунда
   * @format int64
   */
  time_passed: number;
  /**
   * Время (ms) одного шага
   * @format int64
   */
  time_step: number;
  /** История изменения цен. В ответе приходит последние N секунд от текущего момента. */
  price_history: RoundPrice[];
}

export interface LoginResponse {
  /** Конфигурация системы */
  config: Config;
  /** Trader */
  trader: Trader;
  /** Был ли отказ в регистрации (true если был, false если не было) */
  rejected: boolean;
  /** true если создалась новая компания */
  creation: boolean;
}

/** Действие с балансом */
export interface BalanceHistory {
  id: number;
  /**
   * timestamp(ms) создания доната
   * @format int64
   */
  created_at: number;
  kind: BalanceHistoryKind;
  /** ton в decimals 9. Бэкенд отправляет целое число нанотонов (без дробной части) */
  value_ton: Ton;
  /** хеш транзакции */
  tx_hash?: string;
  status: BalanceHistoryStatus;
}

/** Конфигурация системы */
export interface Config {
  /** Адрес пополнения баланса */
  deposit_address: string;
  /** ton в decimals 9. Бэкенд отправляет целое число нанотонов (без дробной части) */
  min_withdrawal: Ton;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

/** Trader */
export interface Trader {
  /**
   * Id трейдера
   * @format int64
   */
  id: number;
  /** Имя трейдера */
  name: string;
  /** адрес вывода ton */
  withdraw_address: string;
  /** Был ли трейдер инициализирован */
  initialized: boolean;
  /**
   * timestamp (ms) создания аккаунта
   * @format int64
   */
  created_at: number;
  /** Язык пользователя (ru/en) */
  locale: string;
  referral_system: ReferralSystem;
  /** Баланс внутриигровых тонов */
  ton_balance: Ton;
  /** Сколько средств выведено */
  claimed: Ton;
  /**
   * количество доступных квестов для выполнения
   * @format int64
   */
  available_tasks_count: number;
  /**
   * количество доступной рекламы с начислением награды
   * @format int64
   */
  available_ads: number;
}

export interface TraderResponse {
  /** Trader */
  trader: Trader;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface ReferralSystem {
  /** процент, получаемый от выигрыша казино с рефералов; приходит строка с десятичным числом (например, "1.5") */
  referral_percent: string;
  /** суммарный баланс, начисленный со ставок рефералов */
  ton_received: Ton;
  /**
   * количество рефералов
   * @format int64
   */
  count: number;
}

/** Редактирование пользователя */
export interface UpdateTraderRequest {
  /** Адрес вывода ton */
  withdraw_address?: string;
  /** Язык */
  locale: "en" | "ru";
}

export interface UpdateTraderResponse {
  /** Trader */
  trader: Trader;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface ConfigResponse {
  /** Конфигурация системы */
  config: Config;
}

/** Данные для оплаты */
export interface SyncTimeResponse {
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface Pagination {
  /**
   * Всего элементов
   * @format int32
   */
  total?: number;
  /**
   * Номер страницы, начиная с 0
   * @format int32
   */
  page: number;
  /**
   * Размер страницы
   * @format int32
   */
  page_size: number;
}

export enum BalanceHistoryKind {
  Deposit = "deposit",
  Withdrawal = "withdrawal",
}

export enum BalanceHistoryStatus {
  Pending = "pending",
  Success = "success",
  Expired = "expired",
}

export enum AccrualStatus {
  Pending = "pending",
  Success = "success",
  Expired = "expired",
}

export enum WithdrawalStatus {
  Pending = "pending",
  Success = "success",
  Expired = "expired",
}

/** Начисление пользователя */
export interface Accrual {
  /** Id доната (его в коммент транзы пихнуть) */
  id: number;
  /**
   * timestamp(ms) создания доната
   * @format int64
   */
  created_at: number;
  /** ton в decimals 9. Бэкенд отправляет целое число нанотонов (без дробной части) */
  value_ton: Ton;
  /** хеш транзакции */
  tx_hash: string;
  status: AccrualStatus;
  viewed: boolean;
}

export interface AccrualRequest {
  /** Кол-во пополнения */
  value_ton?: Ton;
}

/** Данные для оплаты */
export interface AccrualResponse {
  /** Начисление пользователя */
  accrual: Accrual;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface AccrualListByStatusesRequest {
  statuses?: AccrualStatus[];
  /** Учитывается только для success статуса. Если true, то success начисления возвращаются только непросмотренные */
  only_not_viewed?: boolean;
}

export interface AccrualListByStatusesResponse {
  accruals: Accrual[];
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface AccrualListResponse {
  /**
   * Сортировка по статусам и просмотренности.
   * expired не просмотренные, pending, success, expired и expired_and_received
   */
  accruals: Accrual[];
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
  pagination: Pagination;
}

export interface AccrualMarkViewedRequest {
  ids: number[];
}

export interface AccrualMarkViewedResponse {
  /** Trader */
  trader: Trader;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

/** Вывод средств */
export interface WithdrawalRequest {
  /** ton в decimals 9. Бэкенд отправляет целое число нанотонов (без дробной части) */
  value_ton: Ton;
  address: string;
}

/** Вывод средств */
export interface WithdrawalResponse {
  /** Вывод рефералки */
  withdrawal: UserWithdrawal;
  /** Trader */
  trader: Trader;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface WithdrawalListResponse {
  withdrawals: UserWithdrawal[];
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

/** Информация про мемкоин */
export interface Coin {
  /** Полное название мемкоина */
  coin_name: string;
  /** Сокращённое название (например, Bitcoin - BTC) */
  coin_symbol: string;
}

/** Новости при изменении цены */
export interface News {
  /** Новость */
  title: string;
  /** Сокращённое название (например, Bitcoin - BTC) */
  image_url: string;
}

/** Вывод рефералки */
export interface UserWithdrawal {
  /** Id вывода */
  id: number;
  /**
   * timestamp(ms) создания вывода
   * @format int64
   */
  created_at: number;
  /** ton в decimals 9. Бэкенд отправляет целое число нанотонов (без дробной части) */
  value_ton: Ton;
  /** хеш транзакции */
  tx_hash: string;
  address: string;
  status: WithdrawalStatus;
}

export interface BalanceHistoryListResponse {
  history: BalanceHistory[];
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
  pagination: Pagination;
}

/** Обобщенный SSE ответ */
export interface GeneralSub {
  /**
   * open_connection - при открытии SSE коннекта
   * new_round - начало нового раунда
   * trading_start - начало торгов
   * trading_finish - окончание торгов
   * price_update - обновление цен
   * liquidation - ликвидация ордера
   * fixed_orders_history - зафиксированные ордера
   */
  sub_kind: SubKind;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
  /** if sub_kind == price_update */
  price_history_chunk?: RoundPrice[];
  /** if sub_kind one of open_connection, new_round, trading_start, trading_finish */
  current_round?: Round;
  /** if user has order in current round (may be liquidated before) */
  order?: Order;
  /** if there are fixed orders (sub_kind one of fixed_orders_history, open_connection) in current round, that was not displayed before */
  fix_orders?: Order[];
  /** новость при изменении цены  if sub_kind == price_update */
  news?: News;
}

export interface AdsAccrueRewardRequest {
  /** структура клика */
  click: AdsClick;
}

export interface AdsAccrueRewardResponse {
  done: boolean;
}

/** структура клика */
export interface AdsClick {
  /** @format int64 */
  id: number;
  /** @format int64 */
  user_telegram_id: number;
  /**
   * id квеста, к которому привязан клик
   * @format int64
   */
  task_id: number;
  /** payload, который передавался при запросе рекламы */
  payload: string;
}

export interface TaskListResponse {
  tasks: Task[];
  /**
   * кол-во активных квестов
   * @format int64
   */
  active_counter?: number;
}

export interface FullTaskListRequest {
  /** усли true то будут возвращены все квесты вместе с удаленными */
  is_deleted?: boolean;
}

export interface FullTaskListResponse {
  tasks: FullTask[];
}

export interface TaskExecutionCheckRequest {
  /** @format int64 */
  id: number;
}

export interface TaskExecutionCheckResponse {
  /** Задание */
  task: Task;
}

export interface TaskClaimRequest {
  /** @format int64 */
  id: number;
}

export interface TaskClaimResponse {
  /** Следующий уровень задачи или последний */
  task: Task;
  /** Trader */
  trader: Trader;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time: number;
}

export interface TaskCreateRequest {
  kind: TaskKind;
  /** URL задания */
  ru_task_url?: string;
  /** id канала, если нужна проверка */
  ru_chan_id?: string;
  /** URL задания */
  en_task_url?: string;
  /** id канала, если нужна проверка */
  en_chan_id?: string;
  /** рекомендуется при создании указывать true, а потом менять */
  is_deleted: boolean;
  /** уровни */
  levels: {
    /**
     * номер от 1
     * @format int64
     */
    number: number;
    /** base64-encoded image */
    image: string;
    /** Название */
    ru_name: string;
    /** Описание */
    ru_description: string;
    /** Название */
    en_name: string;
    en_description: string;
    /** Награда в тонах (decimals 9) */
    ton_reward?: Ton;
    /**
     * Ограничение на кол-во выполнений уровня квеста
     * @format int64
     */
    execution_limit: number;
  }[];
}

export interface TaskCreateResponse {
  /** Квест со всеми уровнями */
  task: FullTask;
}

export interface TaskUpdateRequest {
  /**
   * id квеста
   * @format int64
   */
  id: number;
  kind: TaskKind;
  /** URL задания */
  ru_task_url?: string;
  /** id канала, если нужна проверка */
  ru_chan_id?: string;
  /** URL задания */
  en_task_url?: string;
  /** id канала, если нужна проверка */
  en_chan_id?: string;
}

export interface TaskUpdateResponse {
  /** Квест со всеми уровнями */
  task: FullTask;
}

/** Уровень задания */
export interface TaskLevelUpdateRequest {
  /**
   * id уровня квеста
   * @format int64
   */
  id_lvl: number;
  /**
   * номер от 1
   * @format int64
   */
  number: number;
  /** Название */
  ru_name: string;
  /** Описание */
  ru_description: string;
  /** Название */
  en_name: string;
  en_description: string;
  /** Награда в тонах (decimals 9) */
  ton_reward?: Ton;
  /**
   * Ограничение на кол-во выполнений уровня квеста
   * @format int64
   */
  execution_limit: number;
}

export interface TaskLevelUpdateResponse {
  /** Квест со всеми уровнями */
  task: FullTask;
}

/** обновление пикчи */
export interface TaskLevelUpdateImageRequest {
  /**
   * id уровня квеста
   * @format int64
   */
  id_lvl: number;
  /** base64-encoded image */
  image: string;
}

export interface TaskDeleteRequest {
  /** @format int64 */
  id: number;
  is_delete: boolean;
}

export interface TaskDeleteResponse {
  /** Квест со всеми уровнями */
  task: FullTask;
}

/** Задание */
export interface Task {
  /** @format int64 */
  id: number;
  kind: TaskKind;
  /** URL задания */
  task_url?: string;
  /** Первый невыполненный или незаклеймленный уровень */
  current_level: TaskLevel;
  /**
   * количество уровней в квесте
   * @format int64
   */
  number_levels: number;
}

/** Квест со всеми уровнями */
export interface FullTask {
  /** @format int64 */
  id: number;
  kind: TaskKind;
  /** URL задания */
  ru_task_url?: string;
  /** URL задания */
  en_task_url?: string;
  /** id канала, если нужна проверка */
  ru_chan_id?: string;
  /** id канала, если нужна проверка */
  en_chan_id?: string;
  is_deleted: boolean;
  levels: FullTaskLevel[];
}

/** Уровень задания */
export interface TaskLevel {
  /** @format int64 */
  id: number;
  /** @format int64 */
  number: number;
  /** URL на картинку */
  image_url: string;
  /** Название */
  name: string;
  /** Описание */
  description: string;
  /** выполнение уровня */
  done?: boolean;
  /** Клейм награды */
  award_received?: boolean;
  /** Награда в тонах */
  ton_reward: Ton;
}

/** Уровень задания полный */
export interface FullTaskLevel {
  /** @format int64 */
  id: number;
  /** @format int64 */
  number: number;
  /** URL на картинку */
  image_url: string;
  /** Название */
  ru_name: string;
  /** Описание */
  ru_description: string;
  /** Название */
  en_name: string;
  /** Описание */
  en_description: string;
  /** Награда в тонах */
  ton_reward: Ton;
  /**
   * кол-во выполнивших уровень
   * @format int64
   */
  number_done: number;
  /**
   * Ограничение на кол-во выполнений уровня квеста
   * @format int64
   */
  execution_limit: number;
}

export enum TaskKind {
  WithoutSubscribe = "without_subscribe",
  SubscribeToTelegram = "subscribe_to_telegram",
  ExternalApi = "external_api",
}

/**
 * open_connection - при открытии SSE коннекта
 * new_round - начало нового раунда
 * trading_start - начало торгов
 * trading_finish - окончание торгов
 * price_update - обновление цен
 * liquidation - ликвидация ордера
 * fixed_orders_history - зафиксированные ордера
 */
export enum SubKind {
  OpenConnection = "open_connection",
  NewRound = "new_round",
  TradingStart = "trading_start",
  TradingFinish = "trading_finish",
  PriceUpdate = "price_update",
  Liquidation = "liquidation",
  FixedOrdersHistory = "fixed_orders_history",
}

/** Типовой запрос для ответа на Post запросы, которые не должны возвращать никаких данных */
export interface SuccessResponse {
  success?: boolean;
  /**
   * timestamp(ms) на сервере
   * @format int64
   */
  server_time?: number;
}

/** Ошибка */
export interface ErrorResponse {
  /** Error code */
  code: number;
  /** Error message (на англ, так как более универсальное) */
  en_message: string;
  /** Error message (локализованное) */
  message: string;
  /** Error detail (всегда на американском) */
  detail: string;
}

/** ton в decimals 9. Бэкенд отправляет целое число нанотонов (без дробной части) */
export type Ton = string;

/** Структура цены рунда и времени ее действия. */
export interface RoundPrice {
  /**
   * timestamp ms. Важно, что до начала торговли время будет в будущем и равно trading_start_at
   * @format int64
   */
  timestamp?: number;
  /** Цена */
  price?: Ton;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://memecoins.customapp.tech/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Memecoins
 * @version 1.0.0
 * @baseUrl https://memecoins.customapp.tech/api
 *
 * Memecoins
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  login = {
    /**
     * @description Первичный заход в игру
     *
     * @tags Game
     * @name LoginCreate
     * @request POST:/login
     * @secure
     */
    loginCreate: (params: RequestParams = {}) =>
      this.request<LoginResponse, ErrorResponse>({
        path: `/login`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  trader = {
    /**
     * @description Синхронизация трейдера
     *
     * @tags Game
     * @name TraderList
     * @request GET:/trader
     * @secure
     */
    traderList: (params: RequestParams = {}) =>
      this.request<TraderResponse, ErrorResponse>({
        path: `/trader`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  updateTrader = {
    /**
     * @description редактирование трейдера
     *
     * @tags Game
     * @name UpdateTraderCreate
     * @request POST:/update_trader
     * @secure
     */
    updateTraderCreate: (req: UpdateTraderRequest, params: RequestParams = {}) =>
      this.request<UpdateTraderResponse, ErrorResponse>({
        path: `/update_trader`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  config = {
    /**
     * @description Синхронизация конфига
     *
     * @tags Game
     * @name ConfigList
     * @request GET:/config
     * @secure
     */
    configList: (params: RequestParams = {}) =>
      this.request<ConfigResponse, ErrorResponse>({
        path: `/config`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  syncTime = {
    /**
     * @description Синхронизация времени
     *
     * @tags Game
     * @name SyncTimeList
     * @request GET:/sync_time
     * @secure
     */
    syncTimeList: (params: RequestParams = {}) =>
      this.request<SyncTimeResponse, ErrorResponse>({
        path: `/sync_time`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  order = {
    /**
     * @description Создать новый ордер
     *
     * @tags Game
     * @name OrderCreate
     * @request POST:/order
     * @secure
     */
    orderCreate: (req: NewOrderRequest, params: RequestParams = {}) =>
      this.request<NewOrderResponse, ErrorResponse>({
        path: `/order`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  orders = {
    /**
     * @description Зафиксировать ордер по ID
     *
     * @tags Game
     * @name PostOrders
     * @request POST:/orders/fix
     * @secure
     */
    postOrders: (req: FixOrderRequest, params: RequestParams = {}) =>
      this.request<FixOrderResponse, ErrorResponse>({
        path: `/orders/fix`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description получить свои ордера
     *
     * @tags Game
     * @name MyListList
     * @request GET:/orders/my_list
     * @secure
     */
    myListList: (
      query: {
        /**
         * Номер страницы
         * @format int64
         */
        page: number;
        /**
         * Размер страницы
         * @format int64
         */
        page_size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOrdersResponse, ErrorResponse>({
        path: `/orders/my_list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description получить закрытые ордера раунда
     *
     * @tags Game
     * @name RoundListList
     * @request GET:/orders/round_list
     * @secure
     */
    roundListList: (
      query: {
        /**
         * Номер страницы
         * @format int64
         */
        page: number;
        /**
         * Размер страницы
         * @format int64
         */
        page_size: number;
        /**
         * Айди раунда
         * @format int64
         */
        round_id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetRoundOrdersResponse, ErrorResponse>({
        path: `/orders/round_list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  round = {
    /**
     * @description Получить текущий раунд
     *
     * @tags Game
     * @name RoundList
     * @request GET:/round
     * @secure
     */
    roundList: (params: RequestParams = {}) =>
      this.request<Round, ErrorResponse>({
        path: `/round`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  events = {
    /**
     * @description Создание подписки
     *
     * @tags Game
     * @name SubscribeList
     * @request GET:/events/subscribe
     */
    subscribeList: (
      query: {
        /** stream id  (default 'messages') */
        stream: string;
        /** параметра тг авторизации, аналогично авторизации по хедеру */
        auth_token: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorResponse>({
        path: `/events/subscribe`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  accrual = {
    /**
     * @description Создать начисление
     *
     * @tags Game
     * @name AccrualCreate
     * @request POST:/accrual
     * @secure
     */
    accrualCreate: (req: AccrualRequest, params: RequestParams = {}) =>
      this.request<AccrualResponse, ErrorResponse>({
        path: `/accrual`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name ListList
     * @request GET:/accrual/list
     * @secure
     */
    listList: (
      query: {
        /**
         * Номер страницы
         * @format int64
         */
        page: number;
        /**
         * Размер страницы
         * @format int64
         */
        page_size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<AccrualListResponse, ErrorResponse>({
        path: `/accrual/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name ListByStatusesCreate
     * @request POST:/accrual/list/by_statuses
     * @secure
     */
    listByStatusesCreate: (req: AccrualListByStatusesRequest, params: RequestParams = {}) =>
      this.request<AccrualListByStatusesResponse, ErrorResponse>({
        path: `/accrual/list/by_statuses`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name MarkViewedCreate
     * @request POST:/accrual/mark_viewed
     * @secure
     */
    markViewedCreate: (req: AccrualMarkViewedRequest, params: RequestParams = {}) =>
      this.request<AccrualMarkViewedResponse, ErrorResponse>({
        path: `/accrual/mark_viewed`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  withdrawal = {
    /**
     * No description
     *
     * @tags Game
     * @name WithdrawalCreate
     * @request POST:/withdrawal
     * @secure
     */
    withdrawalCreate: (req: WithdrawalRequest, params: RequestParams = {}) =>
      this.request<WithdrawalResponse, ErrorResponse>({
        path: `/withdrawal`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name ListList
     * @request GET:/withdrawal/list
     * @secure
     */
    listList: (params: RequestParams = {}) =>
      this.request<WithdrawalListResponse, ErrorResponse>({
        path: `/withdrawal/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  balanceHistory = {
    /**
     * No description
     *
     * @tags Game
     * @name ListList
     * @request GET:/balance_history/list
     * @secure
     */
    listList: (
      query: {
        /**
         * Номер страницы
         * @format int64
         */
        page: number;
        /**
         * Размер страницы
         * @format int64
         */
        page_size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<BalanceHistoryListResponse, ErrorResponse>({
        path: `/balance_history/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  task = {
    /**
     * No description
     *
     * @tags Game
     * @name TaskDetail
     * @request GET:/task/{id}
     * @secure
     */
    taskDetail: (id: number, params: RequestParams = {}) =>
      this.request<Task, ErrorResponse>({
        path: `/task/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name ListList
     * @request GET:/task/list
     * @secure
     */
    listList: (params: RequestParams = {}) =>
      this.request<TaskListResponse, ErrorResponse>({
        path: `/task/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Запрос проверки выполнения задания
     *
     * @tags Game
     * @name ExecutionCheckCreate
     * @request POST:/task/execution_check
     * @secure
     */
    executionCheckCreate: (req: TaskExecutionCheckRequest, params: RequestParams = {}) =>
      this.request<TaskExecutionCheckResponse, ErrorResponse>({
        path: `/task/execution_check`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Получение награды за выполненное задание
     *
     * @tags Game
     * @name ClaimCreate
     * @request POST:/task/claim
     * @secure
     */
    claimCreate: (req: TaskClaimRequest, params: RequestParams = {}) =>
      this.request<TaskClaimResponse, ErrorResponse>({
        path: `/task/claim`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  ads = {
    /**
     * @description Запрос начисления награды за рекламу
     *
     * @tags Game
     * @name AccrueRewardCreate
     * @request POST:/ads/accrue_reward
     * @secure
     */
    accrueRewardCreate: (req: AdsAccrueRewardRequest, params: RequestParams = {}) =>
      this.request<AdsAccrueRewardResponse, ErrorResponse>({
        path: `/ads/accrue_reward`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  admin = {
    /**
     * @description Первичный заход в админку
     *
     * @tags Game
     * @name LoginCreate
     * @request POST:/admin/login
     * @secure
     */
    loginCreate: (params: RequestParams = {}) =>
      this.request<LoginResponse, ErrorResponse>({
        path: `/admin/login`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Создание квеста
     *
     * @tags Game
     * @name TaskCreateCreate
     * @request POST:/admin/task/create
     * @secure
     */
    taskCreateCreate: (req: TaskCreateRequest, params: RequestParams = {}) =>
      this.request<TaskCreateResponse, ErrorResponse>({
        path: `/admin/task/create`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Апдейт квеста
     *
     * @tags Game
     * @name TaskUpdateCreate
     * @request POST:/admin/task/update
     * @secure
     */
    taskUpdateCreate: (req: TaskUpdateRequest, params: RequestParams = {}) =>
      this.request<TaskUpdateResponse, ErrorResponse>({
        path: `/admin/task/update`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Апдейт уровня квеста
     *
     * @tags Game
     * @name TaskLevelUpdateCreate
     * @request POST:/admin/task_level/update
     * @secure
     */
    taskLevelUpdateCreate: (req: TaskLevelUpdateRequest, params: RequestParams = {}) =>
      this.request<TaskLevelUpdateResponse, ErrorResponse>({
        path: `/admin/task_level/update`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Апдейт пикчи уровня квеста
     *
     * @tags Game
     * @name TaskLevelUpdateImageCreate
     * @request POST:/admin/task_level/update_image
     * @secure
     */
    taskLevelUpdateImageCreate: (req: TaskLevelUpdateImageRequest, params: RequestParams = {}) =>
      this.request<TaskLevelUpdateResponse, ErrorResponse>({
        path: `/admin/task_level/update_image`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Создание квеста
     *
     * @tags Game
     * @name TaskDeleteCreate
     * @request POST:/admin/task/delete
     * @secure
     */
    taskDeleteCreate: (req: TaskDeleteRequest, params: RequestParams = {}) =>
      this.request<TaskDeleteResponse, ErrorResponse>({
        path: `/admin/task/delete`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name TaskDetail
     * @request GET:/admin/task/{id}
     * @secure
     */
    taskDetail: (id: number, params: RequestParams = {}) =>
      this.request<FullTask, ErrorResponse>({
        path: `/admin/task/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Game
     * @name TaskListCreate
     * @request POST:/admin/task/list
     * @secure
     */
    taskListCreate: (req: FullTaskListRequest, params: RequestParams = {}) =>
      this.request<FullTaskListResponse, ErrorResponse>({
        path: `/admin/task/list`,
        method: "POST",
        body: req,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
