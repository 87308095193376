import { msg, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { FC } from 'react'

import { AppModalProps } from '@/shared/lib'
import { Button, Dialog, HStack, Icon, VStack } from '@/shared/ui'

import { MicroTonPopover } from '../MicroTonPopover'
import Meaufo from './meafubig.svg?react'
import s from './WelcomeModal.module.scss'

interface WelcomeModalProps extends AppModalProps {

}

export const WelcomeModal: FC<WelcomeModalProps> = ({ open, onClose }) => {
  const { _ } = useLingui()

  return (
    <Dialog
      title={_(msg`Greetings Trader!`)}
      open={open}
      onClose={onClose}
    >
      <div className={s.meaufo}>
        <Meaufo />
      </div>
      <VStack max gap="12" align="start">
        <h5 className={s.title}>
          <Trans>
            Meaufo is a memecoin trader simulator where you can earn TON.
          </Trans>
        </h5>
        <p className={s.text}>
          <Trans>
            Earn on deals every round:
          </Trans>
        </p>
        <HStack gap="8" align="center">
          <Icon name="down" size={[ 24, 24 ]} />
          <span className={s.shortText}>
            <Trans>
              Short to bet on price fall.
            </Trans>
          </span>
        </HStack>
        <HStack gap="8" align="center">
          <Icon name="up" size={[ 24, 24 ]} />
          <span className={s.longText}>
            <Trans>
              Long to bet on price growth.
            </Trans>
          </span>
        </HStack>
        <HStack max className={s.bonus} gap="4">
          <span className={s.bonusText}><Trans> Welcome bonus - </Trans></span>
          <HStack gap="4" align="center">
            <Icon name="ton" size={[ 20, 20 ]} />
            <span className={s.bonusText}>10μ</span>
            <MicroTonPopover />
          </HStack>
        </HStack>
      </VStack>
      <VStack max gap="12">
        <span className={s.gradient}>
          <Trans>
            Train, earn and set profit records!
          </Trans>
        </span>
        <Button
          fillContainer
          variant='special-green'
          onClick={onClose}
        >
          <Trans>
            Close
          </Trans>
        </Button>
      </VStack>
    </Dialog>
  )
}
