import { FC } from 'react'

import { HStack, Icon, Popover } from '@/shared/ui'

import s from './MicroTonPopover.module.scss'

export const MicroTonPopover: FC = () => {
  return (
    <Popover buttonContent={<Icon name="infoOutline" size={[16, 16]} />}>
      <HStack gap="4" align='center'>
        <span className={s.text}>1μ TON (microTON) = 0.000,001 TON</span>
        <Icon name="ton" size={[12, 12]} />
      </HStack>
    </Popover>
  )
}
