import 'react-toastify/dist/ReactToastify.css'
import './styles/global.scss'
import './styles/fonts/connect.scss'

import { useGSAP } from '@gsap/react'
import Clarity from '@microsoft/clarity'
import { setDebug, useLaunchParams } from '@telegram-apps/sdk-react'
import { gsap } from 'gsap'
import MotionPathHelper from 'gsap/MotionPathPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react'

import { AppRouter } from '@/app/providers/router'
import { useBackButtonCloseApp } from '@/features/BackButton'

/**
 * Root content of application
 * @constructor
 */
gsap.registerPlugin(useGSAP)

gsap.registerPlugin(useGSAP, ScrollTrigger)
gsap.registerPlugin(MotionPathHelper)

Clarity.init('pzfbesz3ps')

export const App = () => {
  useBackButtonCloseApp()
  const lp = useLaunchParams()

  // Calling eruda - debug/developer console in tma
  useEffect(() => {
    if (!import.meta.env.DEV) return

    if (lp.startParam === '-1') {
      setDebug(true)
      import('eruda').then(it => it.default.init())
    }
  }, [lp.startParam])

  return (
    <AppRouter />
  )
}
