import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { depositEvents } from '@/entities/deposit'
import { AppModalProps, formatTon, to, useStrictRunOnce } from '@/shared/lib'
import { Button, Dialog, HStack, Icon } from '@/shared/ui'
import { Accrual } from '@/swagger/Api'

import { useDepositMarkViewed } from '../../api/useDepositMarkViewed'
import s from './DepositResultDialog.module.scss'
import Teleport from './teleport.svg?react'

interface DepositResultDialogProps extends AppModalProps {
  accruals: Accrual[]
}

export const DepositResultDialog: FC<DepositResultDialogProps> = ({ open, onClose, accruals }) => {
  const navigate = useNavigate()
  const markViewed = useDepositMarkViewed()

  const navigateToHistory = useCallback(() => {
    navigate(to.history())
    onClose?.()
  }, [onClose])

  useStrictRunOnce(() => {
    accruals.forEach(depositEvents.depositSuccess)
    markViewed.mutate(accruals.map(({ id }) => id))
  })

  return (
    <Dialog
      title='Deposit/withdraw teleported'
      open={open}
      onClose={onClose}
    >
      <div className={s.teleport}>
        <Teleport />
      </div>
      {
        accruals?.map((accrual) => (
          <HStack key={accrual.id} align='center' gap="8">
            <Icon name="ton" size={[32, 32]} />
            <span className={s.ton}>
              {formatTon(accrual.value_ton)}
              {' '}
              &nbsp;TON
            </span>

          </HStack>
        ))
      }
      <Button
        fillContainer
        variant='special-green'
        onClick={navigateToHistory}
      >
        Cool
      </Button>
    </Dialog>
  )
}
